'use client';

import * as React from 'react';
import { FC, MouseEvent, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/navigation';

import { GlobalTranslate } from 'common/i18n/globalTranslate';

import { PAGES } from './PAGES';

interface Props {
  dictionary: Pick<GlobalTranslate, 'Navigation'>;
}

export const MobileMenu: FC<Props> = ({ dictionary: { Navigation } }) => {
  const router = useRouter();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const toggleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(v => (v ? null : event.currentTarget));
  };

  const navigateTo = (url: string) => {
    void router.push(url);
    setAnchorElNav(null);
  };

  const open = !!anchorElNav;

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'main-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="inherit"
        id="main-menu-button"
        size="large"
        onClick={toggleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ position: 'absolute' }}>
        <Menu
          anchorEl={anchorElNav}
          id="main-menu"
          MenuListProps={{
            'aria-labelledby': 'main-menu-button'
          }}
          open={open}
        >
          {PAGES.map(({ href, key }) => (
            <MenuItem key={href} onClick={() => navigateTo(href)}>
              <Typography textAlign="center">{Navigation[key]}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};
