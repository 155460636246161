import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/AppBar/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Breadcrumbs/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Card/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardActionArea/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardContent/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardMedia/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/Toolbar/Toolbar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/components/Icons/LogoSvg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleSwitcher"] */ "/vercel/path0/src/common/components/LocaleSwitcher/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/components/OtherLocales/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/src/common/components/TopAppBar/MobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Editor"] */ "/vercel/path0/src/common/pages/EditorPage/components/Editor.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/HomePage/components/Hero1/hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/HomePage/components/HeroEditing/edit-videos.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/Hero1/hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroHowToTrim/download.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroHowToTrim/trim.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroHowToTrim/upload.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroPlatformBox/platform.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/UploadVideoButton/UploadVideoButton.tsx");
